@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-menu-light .ant-menu-item-selected {
  background-color: #2d3c5a;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-menu-light .ant-menu-item-selected {
  color: #1380ce;
  // font-weight: 600;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover {
  color: #1380ce;
  // font-weight: 600;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #2d3c5a;
}

.ant-menu-light .ant-menu-item-selected {
  color: #1380ce;
  // font-weight: 600;
}

.ant-menu-light .ant-menu-item {
  color: #b8bcc1 !important;
}

.ant-menu-light .ant-menu-submenu-title {
  color: #b8bcc1 !important;
}

.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover {
  color: #1380ce;
  // font-weight: 600;
}

.summary {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-input-affix-wrapper > input.ant-input {
    text-align: right;
  }
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  background: #f7f7f7;
  border: 1px dashed red;
}

.data-pemesan {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-form-item .ant-form-item-label > label {
    color: #acbacd;
  }

  .css-13cymwt-control {
    background-color: #f1f5f9;
  }

  :where(.css-dev-only-do-not-override-1ck3jst).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #f1f5f9;
  }

  :where(.css-dev-only-do-not-override-1ck3jst).ant-picker {
    background-color: #f1f5f9;
  }
}

.table-section-product {
  // :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper
  //   .ant-table-container
  //   table
  //   > thead
  //   > tr:first-child
  //   > *:last-child {
  //   border-start-end-radius: 0;
  // }

  // :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper
  //   .ant-table-container
  //   table
  //   > thead
  //   > tr:first-child
  //   > *:first-child {
  //   border-start-start-radius: 0;
  // }

  :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-thead > tr > td {
    position: relative;
    background: #111e2b;
    // border-radius: 10px;
    // width: 100vw;
  }

  .ant-table-thead > tr > th {
    position: relative;
    background: #141d2f;
    color: #b8bcc1;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    // width: 100vw;
  }

  .ant-table-tbody .ant-table-row {
    display: table-row;
    vertical-align: top;
  }
}

.table-section-master-default {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-thead > tr > td {
    position: relative;
    background: #111e2b;
    // border-radius: 10px;
    // width: 100vw;
  }

  .ant-table-thead > tr > th {
    position: relative;
    background: #141d2f;
    color: #b8bcc1;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    // width: 100vw;
  }

  .ant-table-tbody .ant-table-row {
    display: table-row;
  }

  .ant-table-wrapper .ant-table-tbody .ant-table-row:nth-child(2n + 1) .ant-table-cell-fix-left {
    position: relative;
    background: #f3f4f6;
    // border-radius: 10px;
    // width: 100vw;
  }
}

.table-section-user {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-thead > tr > td {
    position: relative;
    background: #111e2b;
    // border-radius: 10px;
    // width: 100vw;
  }

  .ant-table-thead > tr > th {
    position: relative;
    background: #141d2f;
    color: #b8bcc1;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    // width: 100vw;
  }

  .ant-table-tbody .ant-table-row {
    display: table-row;
    vertical-align: top;
  }

  .ant-table-wrapper .ant-table-tbody .ant-table-row:nth-child(2n + 1) .ant-table-cell-fix-left {
    position: relative;
    background: #f1f5f9;
    // border-radius: 10px;
    // width: 100vw;
  }
}

.section-form-recipient {
  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }
}

.section-checkbox-resi {
  .ant-checkbox-group {
    display: inline-block;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0px;
  }
}

.table-section-master-produk {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-thead > tr > td {
    position: relative;
    background: #111e2b;
    // border-radius: 10px;
    // width: 100vw;
  }

  .ant-table-thead > tr > th {
    position: relative;
    background: #141d2f;
    color: #b8bcc1;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    // width: 100vw;
  }

  .ant-table-tbody .ant-table-row {
    display: table-row;
    vertical-align: top;
  }
}

.table-section-variant-produk {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-thead > tr > td {
    position: relative;
    background: #111e2b;
    // border-radius: 10px;
    // width: 100vw;
  }

  .ant-table-thead > tr > th {
    position: relative;
    background: #141d2f;
    color: #b8bcc1;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    // width: 100vw;
  }

  .ant-table-tbody .ant-table-row {
    display: table-row;
  }
}

.payment-proof {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-upload-wrapper
    .ant-upload-list.ant-upload-list-picture
    .ant-upload-list-item-error,
  :where(.css-dev-only-do-not-override-1ck3jst).ant-upload-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-error,
  :where(.css-dev-only-do-not-override-1ck3jst).ant-upload-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-error {
    border-color: #0047b7;
  }

  :where(.css-dev-only-do-not-override-1ck3jst).ant-tooltip {
    display: none;
  }
}

.search-section {
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 8px 0 0 8px !important;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border: 1px solid #01497c !important;
    background-color: #01497c !important;
    border-radius: 0 8px 8px 0 !important;
    padding-top: 3px;
    color: #fff;
  }
}

.section-checkbox-permission {
  .ant-checkbox-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  :where(.css-dev-only-do-not-override-1ck3jst).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0a91b1;
    border-color: #0a91b1;
  }
}

.ant-pagination {
  @apply flex order-5;

  .ant-pagination-item-link {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #141e30;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #141e30;
  }

  .ant-pagination-next .ant-pagination-item-link {
    color: #141e30;
    border-radius: 30px;
  }

  .ant-pagination-prev .ant-pagination-item-link {
    color: #141e30;
  }

  .ant-pagination-total-text {
    @apply mr-auto;
  }

  .ant-pagination-options {
    @apply float-left;
  }

  .ant-pagination-item {
    border-radius: 30px !important;
    a {
      color: #141e30;
    }
  }

  .ant-pagination-item-active {
    background-color: #b8bcc1;
    border: none;

    a {
      color: #fff;
    }
    a:hover {
      color: #fff;
    }
  }

  // Prev Next
  .ant-pagination-disabled .ant-pagination-item-link {
    border-radius: 30px;
  }
}

.advertiser-table {
  tbody {
    tr {
      td {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }
  }
}

.dealmaker-report-table {
  thead {
    tr {
      th {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }

    tr {
      th {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }
  }
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  background: #acbacd;
}

// #20485a
// #365d70
// #dff6e4

#table-shipping {
  .ant-table-thead > tr > th.ant-table-cell {
    text-align: center;
  }

  .ant-table-tbody > tr > td.ant-table-cell {
    text-align: center;
  }
}
