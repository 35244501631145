/* NotFound.css */
.notfound-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  text-align: center;
  padding: 20px;
}

.notfound-content {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notfound-image {
  max-width: 100%;
  height: auto;
}

.notfound-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.notfound-link:hover {
  background-color: #0056b3;
}
